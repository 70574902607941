/* @noflow */
/* eslint-disable react/jsx-filename-extension, react/prop-types */
import React from 'react';
import {css} from '@emotion/core';
import TurnByTurn from '../assets/svg/turn-by-turn-directions-icon.svg';
import LiveTraffic from '../assets/svg/live-traffic-updates-icon.svg';
import RestaurantsAndShops from '../assets/svg/restaurants-and-shops-icon.svg';
import Lodging from '../assets/svg/lodging_icon.svg';
import ArrowButton from './ArrowButton';

const FeatureHighlights = ({onClick}) => {
    return (
        <aside
            css={css`
                background-color: rgba(0, 0, 0, 0.8);
                height: 128px;
                padding: 1rem;
                position: absolute;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 20px;
                z-index: 2;
                div {
                    color: #f4f4f4;
                    padding: 0 1rem;
                    font-size: 18px;
                    float: left;

                    span {
                        color: #f4f4f4;
                    }

                    @media (max-width: 600px) {
                        display: block;
                    }

                    @media (max-width: 599px) {
                        font-size: 16px;
                    }
                }

                h4 {
                    color: #fdfffd;
                    margin: 0px 0 20px 0;
                    font-weight: normal;
                    @media (max-width: 1300px) {
                        font-size: 13px;
                        margin: 0px 0 10px 0;
                    }

                    @media (max-width: 1100px) {
                        font-size: 15px;
                    }
                }

                button {
                    color: #60a0ff;
                    text-align: left;
                    font-size: 18px;
                    display: block;
                    font-weight: bold;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    padding-left: 0px;
                }

                @media (max-height: 700px) {
                    display: none;
                }

                @media (max-width: 800px) {
                    display: none;
                }

                @media (max-width: 400px) {
                    font-size: 10px;
                }

                @media (max-width: 1300px) {
                    height: 90px;
                    margin-top: 5px;
                    padding: 5px;
                }

                @media (max-width: 1100px) {
                    height: 120px;
                }

                .feature-container {
                    @media (max-width: 1300px) {
                        div > span,
                        div > button {
                            font-size: 14px;
                        }
                    }

                    @media (max-width: 1100px) {
                        width: 40%;
                        margin-left: 10%;
                        div > span,
                        div > button {
                            font-size: 16px;
                        }
                    }
                }
            `}
        >
            <div>
                <h4>Feature Highlights</h4>
                <div className="feature-container">
                    <TurnByTurn
                        css={css`
                            float: left;
                        `}
                    />
                    <div>
                        <span>Turn by Turn Directions</span>
                        <ArrowButton className="right" onClick={onClick} />
                    </div>
                </div>
                <div className="feature-container">
                    <LiveTraffic
                        css={css`
                            float: left;
                        `}
                    />
                    <div>
                        <span>Live Traffic Updates</span>
                        <ArrowButton className="left" onClick={onClick} />
                    </div>
                </div>
                <div className="feature-container">
                    <RestaurantsAndShops
                        css={css`
                            float: left;
                        `}
                    />
                    <div>
                        <span>Restaurants and Shops</span>
                        <ArrowButton className="right" onClick={onClick} />
                    </div>
                </div>
                <div className="feature-container">
                    <Lodging
                        css={css`
                            float: left;
                        `}
                    />
                    <div>
                        <span>Lodging and Hotels</span>
                        <ArrowButton className="left" onClick={onClick} />
                    </div>
                </div>
            </div>
        </aside>
    );
};

export default FeatureHighlights;
