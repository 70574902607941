/* @noflow */
/* eslint-disable camelcase, react/jsx-filename-extension */
import React, {FC} from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';

import {chromeExtensionLink} from '../../../constants';
// @ts-expect-error
import Modal from '../../Modal';
import Button from '../../Button';
// @ts-expect-error
import TermsOfService from '../../TermsOfService';
import MapquestLogo from '../../../assets/svg/mapquest-logo-black.svg';
import Arrow from '../../../assets/svg/white-chevron.svg';
// @ts-expect-error
import FeatureHighlights from '../../FeatureHighlights';
import {ExperimentProps} from '../type';

const LP3: FC<ExperimentProps> = ({onCtaClick}) => {
    const site = useStaticQuery(graphql`
        query LP3 {
            map: file(relativePath: {eq: "mapNewYork.png"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            logo: file(relativePath: {eq: "mapquest-logo-white.png"}) {
                childImageSharp {
                    fixed(width: 220, quality: 30) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    const spanStyles: React.CSSProperties = {
        fontSize: '20px',
        color: '#898988',
        fontWeight: 600,
        textAlign: 'left',
        paddingLeft: '5px',
        marginTop: '-2px'
    };

    return (
        <>
            <Img
                fixed={site.map.childImageSharp.fixed}
                style={{
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    zIndex: '0'
                }}
                imgStyle={{
                    zIndex: '0',
                    position: 'relative'
                }}
            />
            <Img
                fixed={site.logo.childImageSharp.fixed}
                className="mapquest-logo-white"
                style={{
                    height: '41px',
                    width: '250px',
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    zIndex: '1'
                }}
                imgStyle={{
                    zIndex: '1',
                    position: 'relative'
                }}
            />
            <Modal maxWidth="890px" marginTop="0px" innerMargin="10%" backgroundColor="rgba(0,0,0,0.55)" padding="0">
                <aside
                    css={css`
                        padding: 2rem 3rem 1rem;
                        width: 100%;
                    `}
                >
                    <h1
                        css={css`
                            font-size: 2rem;
                            font-weight: 600;
                            line-height: 2.75rem;
                            color: #565656;
                            letter-spacing: 0.05rem;
                            @media (max-width: 600px) {
                                font-size: 1.5rem;
                                line-height: normal;
                            }
                        `}
                    >
                        Quick Maps and Driving
                        <br />
                        Directions from{' '}
                        <MapquestLogo
                            css={css`
                                margin: -6px auto;
                                opacity: 0.6;
                            `}
                        />
                    </h1>
                    <p
                        css={css`
                            font-size: 18px;
                            padding: 0 70px;
                            height: 100%;
                            margin: 20px 0 50px;

                            @media only screen and (max-width: 600px) {
                                padding: 0px;
                                margin: 20px 0;
                                font-size: 12px;
                            }

                            @media (min-width: 400px) and (max-width: 775px) {
                                margin: 20px 0;
                                font-size: 14px;
                            }

                            @media (min-width: 776px) and (max-width: 999px) {
                                margin: 20px 0;
                                padding: 0 50px;
                                font-size: 16px;
                            }
                            @media (min-width: 1000px) {
                                margin: 20px 0;
                                padding: 0 50px;
                                font-size: 16px;
                            }
                        `}
                    >
                        Install the MapQuest Directions extension to quickly access maps, driving directions and live
                        traffic & custom web search on your Chrome™ new tab.
                    </p>

                    <Button
                        text="CONTINUE"
                        img={
                            <span>
                                <Arrow
                                    css={css`
                                        margin-left: 30px;
                                        padding-top: 5px;
                                    `}
                                />
                            </span>
                        }
                        ariaLabel="CONTINUE"
                        link={chromeExtensionLink}
                        onClick={onCtaClick}
                        overrideButtonStyles={`
                            font-size: 34px;
                            background-color: #4c8bf5;
                            color: #fff;
                            padding-top: 30px;
                            &:hover {
                                background-color: rgb(76, 161, 245, 0.761);
                            }
                            
                            span.continue {
                                width: 50%;
                            }

                            width: 493px;
                            height: 109px;
                            margin:0 auto 5% auto;

                            @media only screen and (max-width: 600px) {
                                margin: 0px auto 5% auto;
                                height: 80px;
                                padding-top: 20px;
                                font-size: 28px;
                            }

                            @media (min-width: 601px) and (max-width: 775px) {
                                margin: 20px auto 5% auto;
                            }
                            @media (min-width: 775px) and (max-width: 1000px) {
                                height: 80px;
                                padding-top: 20px;
                                font-size: 30px;
                            }

                        `}
                    >
                        CONTINUE
                    </Button>
                    <TermsOfService
                        additionalLinks={
                            <a
                                href="https://hello.mapquest.com/attributions"
                                target="__blank"
                                rel="noopener noreferrer"
                            >
                                EULA
                            </a>
                        }
                        conjuction=" and "
                        textStyles={`
                            font-size: 13px;
                            width: 100%;
                            padding: 0 70px;
                            height: 36px;
                            color: #424242;
                            a {
                                text-decoration: underline;
                            }

                            @media (max-width: 600px) {
                                padding: 0px;
                                font-size: 10px;

                            }

                            @media (max-height: 900px) {
                                margin: 0px auto;
                            }
                        `}
                        browser="Chrome™"
                    />
                </aside>
                <div
                    css={css`
                        height: 95px;
                        width: 100%;
                        max-width: 890px;
                        background-color: #d4d4d4;
                        margin: 20px auto 0px auto;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;

                        @media (max-width: 775px) {
                            margin: 5px auto -17px auto;
                            height: 185px;
                        }

                        @media (max-width: 1100px) {
                            font-size: 14px;
                            margin: 35px auto -17px auto;
                        }
                    `}
                >
                    <div
                        css={css`
                            width: 25%;
                            display: inline-flex;
                            font-size: 15px;
                            color: #898988;
                            font-weight: 600;
                            margin: 2% auto;
                            padding: 10px 0px;

                            > span {
                                font-size: 18px;
                                @media (max-width: 900px) {
                                    font-size: 14px;
                                }
                            }

                            @media (max-width: 775px) {
                                display: block;
                                padding: 0px 5px;
                                line-height: 16px;
                            }
                        `}
                    >
                        Step 1:{' '}
                        <span style={spanStyles}>
                            Click &ldquo;
                            <button
                                onClick={onCtaClick}
                                type="button"
                                css={css`
                                    color: #60a0ff;
                                    font-weight: 600;
                                    outline: none;
                                    background-color: transparent;
                                    border: none;
                                    font-size: 20px;
                                    padding: 0px;
                                    cursor: pointer;
                                    @media (max-width: 900px) {
                                        font-size: 13px;
                                    }
                                `}
                            >
                                Continue
                            </button>
                            &ldquo;
                        </span>
                    </div>
                    <div
                        css={css`
                            width: 26%;
                            display: inline-flex;
                            font-size: 14px;
                            color: #898988;
                            font-weight: 600;
                            margin: 2% auto;
                            padding: 10px 0px;

                            span {
                                font-size: 18px;
                                @media (max-width: 900px) {
                                    font-size: 13px;
                                }
                            }

                            @media (max-width: 775px) {
                                display: block;
                            }
                        `}
                    >
                        Step 2: <span style={spanStyles}>Add to Chrome™</span>
                    </div>
                    <div
                        css={css`
                            width: 40%;
                            display: inline-flex;
                            margin-left: 15px;
                            font-size: 14px;
                            color: #898988;
                            margin: 1% auto;
                            font-weight: 600;

                            span {
                                font-size: 18px;

                                @media (max-width: 900px) {
                                    font-size: 13px;
                                }
                            }

                            @media (max-width: 775px) {
                                display: block;
                            }
                        `}
                    >
                        Step 3:
                        <span style={spanStyles}>
                            Access Maps and Directions on <br /> your Chrome™ new tab
                        </span>
                    </div>
                </div>
            </Modal>
            <FeatureHighlights onClick={onCtaClick} />
        </>
    );
};

export default LP3;
