/* @noflow */
/* eslint-disable react/jsx-filename-extension, react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import {css} from '@emotion/core';

import Arrow from '../assets/svg/shape.svg';

const ArrowButton = ({onClick, className}) => {
    return (
        <button
            rel="noopener noreferrer"
            onClick={onClick}
            css={css`
                cursor: pointer;
            `}
            className={className}
        >
            Continue
            <Arrow
                css={css`
                    padding-top: 3px;
                    margin-left: 5px;
                `}
            />
        </button>
    );
};

ArrowButton.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string
};

ArrowButton.defaultProps = {
    onClick: () => {},
    className: ''
};

export default ArrowButton;
